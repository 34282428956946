export const EVENTS_FETCH_IN_PROGRESS = 'EVENTS_FETCH_IN_PROGRESS';
export const EVENTS_FETCH_SUCCESS = 'EVENTS_FETCH_SUCCESS';
export const EVENTS_FETCH_ERROR = 'EVENTS_FETCH_ERROR';

export const ADD_EVENT_DIALOG_SHOW = 'ADD_EVENT_DIALOG_SHOW';
export const ADD_EVENT_DIALOG_HIDE = 'ADD_EVENT_DIALOG_HIDE';

export const EVENT_IMAGE_URL_SET = 'EVENT_IMAGE_URL_SET';
export const EVENT_NAME_SET = 'EVENT_NAME_SET';
export const EVENT_VENUE_SET = 'EVENT_VENUE_SET';
export const EVENT_DESCRIPTION_SET = 'EVENT_DESCRIPTION_SET';
export const EVENT_START_TIME_SET = 'EVENT_START_TIME_SET';
export const EVENT_END_TIME_SET = 'EVENT_END_TIME_SET';

export const ADD_EVENT_IN_PROGRESS = 'ADD_EVENT_IN_PROGRESS';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_ERROR = 'ADD_EVENT_ERROR';

export const UPDATE_EVENT_IN_PROGRESS = 'UPDATE_EVENT_IN_PROGRESS';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const DELETE_EVENT_IN_PROGRESS = 'DELETE_EVENT_IN_PROGRESS';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const DELETE_EVENT_DIALOG_SHOW = 'DELETE_EVENT_DIALOG_SHOW';
export const DELETE_EVENT_DIALOG_HIDE = 'DELETE_EVENT_DIALOG_HIDE';
