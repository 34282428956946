import { combineReducers } from 'redux';
import {
    ADD_GATED_ACCESS_DIALOG_HIDE,
    ADD_GATED_ACCESS_DIALOG_SHOW,
    ADD_GATED_ACCESS_ERROR,
    ADD_GATED_ACCESS_IN_PROGRESS,
    ADD_GATED_ACCESS_SUCCESS,
    DELETE_GATED_ACCESS_DIALOG_HIDE,
    DELETE_GATED_ACCESS_DIALOG_SHOW,
    DELETE_GATED_ACCESS_ERROR,
    DELETE_GATED_ACCESS_IN_PROGRESS,
    DELETE_GATED_ACCESS_SUCCESS,
    GATED_ACCESS_DESCRIPTION_SET,
    GATED_ACCESS_END_TIME_SET,
    GATED_ACCESS_NAME_SET,
    GATED_ACCESS_START_TIME_SET,
    GATED_ACCESSES_FETCH_ERROR,
    GATED_ACCESSES_FETCH_IN_PROGRESS,
    GATED_ACCESSES_FETCH_SUCCESS,
    UPDATE_GATED_ACCESS_ERROR,
    UPDATE_GATED_ACCESS_IN_PROGRESS,
    UPDATE_GATED_ACCESS_SUCCESS,
} from '../constants/gatedAccess';

const gatedAccesses = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case GATED_ACCESSES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case GATED_ACCESSES_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case GATED_ACCESSES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addGatedAccess = (state = {
    dialog: false,
    inProgress: false,
    value: {},
    name: '',
    description: '',
    startTime: null,
    endTime: null,
}, action) => {
    switch (action.type) {
    case ADD_GATED_ACCESS_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
            value: action.value,
            name: action.value && action.value.name ? action.value.name : '',
            description: action.value && action.value.description ? action.value.description : '',
            startTime: action.value && action.value.start_time ? action.value.start_time : null,
            endTime: action.value && action.value.end_time ? action.value.end_time : null,
        };
    case GATED_ACCESS_NAME_SET:
        return {
            ...state,
            name: action.value,
        };
    case GATED_ACCESS_DESCRIPTION_SET:
        return {
            ...state,
            description: action.value,
        };
    case GATED_ACCESS_START_TIME_SET:
        return {
            ...state,
            startTime: action.value,
        };
    case GATED_ACCESS_END_TIME_SET:
        return {
            ...state,
            endTime: action.value,
        };
    case ADD_GATED_ACCESS_DIALOG_HIDE:
        return {
            ...state,
            dialog: false,
            name: '',
            description: '',
            startTime: null,
            endTime: null,
        };
    case ADD_GATED_ACCESS_IN_PROGRESS:
    case DELETE_GATED_ACCESS_IN_PROGRESS:
    case UPDATE_GATED_ACCESS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_GATED_ACCESS_SUCCESS:
    case UPDATE_GATED_ACCESS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            dialog: false,
        };
    case ADD_GATED_ACCESS_ERROR:
    case UPDATE_GATED_ACCESS_ERROR:
    case DELETE_GATED_ACCESS_SUCCESS:
    case DELETE_GATED_ACCESS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_GATED_ACCESS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_GATED_ACCESS_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    gatedAccesses,
    addGatedAccess,
    deleteDialog,
});
