export const GATED_ACCESSES_FETCH_IN_PROGRESS = 'GATED_ACCESSES_FETCH_IN_PROGRESS';
export const GATED_ACCESSES_FETCH_SUCCESS = 'GATED_ACCESSES_FETCH_SUCCESS';
export const GATED_ACCESSES_FETCH_ERROR = 'GATED_ACCESSES_FETCH_ERROR';

export const ADD_GATED_ACCESS_DIALOG_SHOW = 'ADD_GATED_ACCESS_DIALOG_SHOW';
export const ADD_GATED_ACCESS_DIALOG_HIDE = 'ADD_GATED_ACCESS_DIALOG_HIDE';

export const GATED_ACCESS_NAME_SET = 'GATED_ACCESS_NAME_SET';
export const GATED_ACCESS_DESCRIPTION_SET = 'GATED_ACCESS_DESCRIPTION_SET';
export const GATED_ACCESS_START_TIME_SET = 'GATED_ACCESS_START_TIME_SET';
export const GATED_ACCESS_END_TIME_SET = 'GATED_ACCESS_END_TIME_SET';

export const ADD_GATED_ACCESS_IN_PROGRESS = 'ADD_GATED_ACCESS_IN_PROGRESS';
export const ADD_GATED_ACCESS_SUCCESS = 'ADD_GATED_ACCESS_SUCCESS';
export const ADD_GATED_ACCESS_ERROR = 'ADD_GATED_ACCESS_ERROR';

export const UPDATE_GATED_ACCESS_IN_PROGRESS = 'UPDATE_GATED_ACCESS_IN_PROGRESS';
export const UPDATE_GATED_ACCESS_SUCCESS = 'UPDATE_GATED_ACCESS_SUCCESS';
export const UPDATE_GATED_ACCESS_ERROR = 'UPDATE_GATED_ACCESS_ERROR';

export const DELETE_GATED_ACCESS_IN_PROGRESS = 'DELETE_GATED_ACCESS_IN_PROGRESS';
export const DELETE_GATED_ACCESS_SUCCESS = 'DELETE_GATED_ACCESS_SUCCESS';
export const DELETE_GATED_ACCESS_ERROR = 'DELETE_GATED_ACCESS_ERROR';

export const DELETE_GATED_ACCESS_DIALOG_SHOW = 'DELETE_GATED_ACCESS_DIALOG_SHOW';
export const DELETE_GATED_ACCESS_DIALOG_HIDE = 'DELETE_GATED_ACCESS_DIALOG_HIDE';
