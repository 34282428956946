import { combineReducers } from 'redux';
import {
    ADD_ENTRY_DIALOG_HIDE,
    ADD_ENTRY_DIALOG_SHOW,
    ADD_ENTRY_ERROR,
    ADD_ENTRY_IN_PROGRESS,
    ADD_ENTRY_SUCCESS,
    DELETE_ENTRY_DIALOG_HIDE,
    DELETE_ENTRY_DIALOG_SHOW,
    DELETE_ENTRY_ERROR,
    DELETE_ENTRY_IN_PROGRESS,
    DELETE_ENTRY_SUCCESS,
    ENTRIES_FETCH_ERROR,
    ENTRIES_FETCH_IN_PROGRESS,
    ENTRIES_FETCH_SUCCESS,
    ENTRY_DENY_SET,
    ENTRY_TICKET_ID_SET,
} from '../constants/entries';
import { DEFAULT_COUNT, DEFAULT_ORDER, DEFAULT_SORT_BY } from '../config';

const entries = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ENTRIES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ENTRIES_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case ENTRIES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addEntry = (state = {
    dialog: false,
    inProgress: false,
    value: {},
    ticketID: '',
    deny: false,
}, action) => {
    switch (action.type) {
    case ADD_ENTRY_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
            value: action.value,
            ticketID: action.value && action.value.ticket_id ? action.value.ticket_id : '',
            deny: action.value && action.value.deny ? action.value.deny : false,
        };
    case ENTRY_TICKET_ID_SET:
        return {
            ...state,
            ticketID: action.value,
        };
    case ENTRY_DENY_SET:
        return {
            ...state,
            deny: action.value,
        };
    case ADD_ENTRY_DIALOG_HIDE:
        return {
            ...state,
            dialog: false,
            value: {},
            ticketID: '',
            deny: false,
        };
    case ADD_ENTRY_IN_PROGRESS:
    case DELETE_ENTRY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_ENTRY_SUCCESS:
        return {
            ...state,
            inProgress: false,
            dialog: false,
        };
    case ADD_ENTRY_ERROR:
    case DELETE_ENTRY_SUCCESS:
    case DELETE_ENTRY_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_ENTRY_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_ENTRY_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    entries,
    addEntry,
    deleteDialog,
});
