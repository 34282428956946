export const ENTRIES_FETCH_IN_PROGRESS = 'ENTRIES_FETCH_IN_PROGRESS';
export const ENTRIES_FETCH_SUCCESS = 'ENTRIES_FETCH_SUCCESS';
export const ENTRIES_FETCH_ERROR = 'ENTRIES_FETCH_ERROR';

export const ADD_ENTRY_DIALOG_SHOW = 'ADD_ENTRY_DIALOG_SHOW';
export const ADD_ENTRY_DIALOG_HIDE = 'ADD_ENTRY_DIALOG_HIDE';

export const ENTRY_TICKET_ID_SET = 'ENTRY_TICKET_ID_SET';
export const ENTRY_DENY_SET = 'ENTRY_DENY_SET';

export const ADD_ENTRY_IN_PROGRESS = 'ADD_ENTRY_IN_PROGRESS';
export const ADD_ENTRY_SUCCESS = 'ADD_ENTRY_SUCCESS';
export const ADD_ENTRY_ERROR = 'ADD_ENTRY_ERROR';

export const DELETE_ENTRY_IN_PROGRESS = 'DELETE_ENTRY_IN_PROGRESS';
export const DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS';
export const DELETE_ENTRY_ERROR = 'DELETE_ENTRY_ERROR';

export const DELETE_ENTRY_DIALOG_SHOW = 'DELETE_ENTRY_DIALOG_SHOW';
export const DELETE_ENTRY_DIALOG_HIDE = 'DELETE_ENTRY_DIALOG_HIDE';
