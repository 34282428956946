import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../Home/AddEventDialog/index.css';
import TextField from '../../../components/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Stack from '@mui/material/Stack';
import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import moment from 'moment';
import calendarIcon from '../../../assets/calendar.png';
import CircularProgress from '../../../components/CircularProgress';
import { showMessage } from '../../../actions/snackbar';
import {
    addGatedAccess,
    fetchGatedAccess,
    hideAddGatedAccessDialog,
    setGatedAccessDescription,
    setGatedAccessEndTime,
    setGatedAccessName,
    setGatedAccessStartTime,
    updateGatedAccess,
} from '../../../actions/gatedAccess';

const AddGatedAccessDialog = (props) => {
    const eventID = props.match && props.match.params && props.match.params.eventID;

    const handleStartTime = (value) => {
        if (moment().diff(value) > 0) {
            const now = new Date();
            props.setGatedAccessStartTime(now);

            return;
        }

        props.setGatedAccessStartTime(value);
    };

    const handleEndTime = (value) => {
        if (moment().diff(value) > 0) {
            const now = new Date();
            props.setGatedAccessEndTime(now);

            return;
        }

        props.setGatedAccessEndTime(value);
    };

    const handleAdd = () => {
        const data = {
            name: props.name,
        };

        if (props.description) {
            data.description = props.description;
        }
        if (props.startTime) {
            data.startTime = props.startTime;
        }
        if (props.endTime) {
            data.endTime = props.endTime;
        }

        props.addGatedAccess(eventID, data, (result) => {
            if (result) {
                props.fetchGatedAccess(eventID);
            }
        });
    };

    const handleUpdate = () => {
        const data = {};

        if (props.value && props.value.name !== props.name) {
            data.name = props.name;
        }
        if (props.value && props.value.description !== props.description) {
            data.description = props.description;
        }
        if (props.value && props.value.start_time !== props.startTime) {
            data.startTime = props.startTime;
        }
        if (props.value && props.value.end_time !== props.endTime) {
            data.endTime = props.endTime;
        }

        if (Object.keys(data).length === 0) {
            props.showMessage('Every thing is upto date');

            return;
        }

        props.updateGatedAccess(eventID, props.value && props.value._id, data, (result) => {
            if (result) {
                props.fetchGatedAccess(eventID);
            }
        });
    };

    const disable = props.name === '' || props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                {props.value && props.value._id
                    ? 'Update Gated Access'
                    : 'Add Gated Access'}
            </DialogTitle>
            <DialogContent>
                {props.inProgress ? <CircularProgress className="full_screen"/> : null}
                <div className="row">
                    <h2>Name</h2>
                    <TextField
                        id="gated_access_name"
                        name="gatedAccessName"
                        placeholder="Enter Name"
                        value={props.name}
                        variant="filled"
                        onChange={props.setGatedAccessName}/>
                </div>
                <div className="row">
                    <h2>Description</h2>
                    <TextField
                        id="gated_access_description"
                        name="gatedAccessDescription"
                        placeholder="Enter Description"
                        value={props.description}
                        variant="filled"
                        onChange={props.setGatedAccessDescription}/>
                </div>
                <div className="row">
                    <h2>Start Date</h2>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack className="date_field" spacing={3}>
                            <MobileDateTimePicker
                                for
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="calendar" src={calendarIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                                minDateTime={new Date()}
                                renderInput={(params) => <MaterialTextField
                                    className="text_field"
                                    placeholder="Start Time"
                                    {...params} />}
                                value={props.startTime}
                                onChange={handleStartTime}/>
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div className="row">
                    <h2>End Date</h2>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack className="date_field" spacing={3}>
                            <MobileDateTimePicker
                                for
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="calendar" src={calendarIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                                minDateTime={new Date()}
                                renderInput={(params) => <MaterialTextField
                                    className="text_field"
                                    placeholder="End Time"
                                    {...params} />}
                                value={props.endTime}
                                onChange={handleEndTime}/>
                        </Stack>
                    </LocalizationProvider>
                </div>
            </DialogContent>
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                {props.value && props.value._id
                    ? <Button autoFocus className="add_button" disabled={disable} onClick={handleUpdate}>
                        Update
                    </Button>
                    : <Button autoFocus className="add_button" disabled={disable} onClick={handleAdd}>
                        Add
                    </Button>}
            </DialogActions>
        </Dialog>
    );
};

AddGatedAccessDialog.propTypes = {
    addGatedAccess: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
    fetchGatedAccess: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setGatedAccessDescription: PropTypes.func.isRequired,
    setGatedAccessEndTime: PropTypes.func.isRequired,
    setGatedAccessName: PropTypes.func.isRequired,
    setGatedAccessStartTime: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    updateGatedAccess: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    endTime: PropTypes.any,
    match: PropTypes.shape({
        params: PropTypes.shape({
            eventID: PropTypes.string,
        }),
    }),
    startTime: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.gatedAccess.addGatedAccess.dialog,
        value: state.gatedAccess.addGatedAccess.value,
        inProgress: state.gatedAccess.addGatedAccess.inProgress,
        name: state.gatedAccess.addGatedAccess.name,
        description: state.gatedAccess.addGatedAccess.description,
        startTime: state.gatedAccess.addGatedAccess.startTime,
        endTime: state.gatedAccess.addGatedAccess.endTime,
    };
};

const actionToProps = {
    addGatedAccess,
    fetchGatedAccess,
    hideDialog: hideAddGatedAccessDialog,
    setGatedAccessName,
    setGatedAccessDescription,
    setGatedAccessStartTime,
    setGatedAccessEndTime,
    showMessage,
    updateGatedAccess,
};

export default withRouter(connect(stateToProps, actionToProps)(AddGatedAccessDialog));
