import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import { Button } from '@material-ui/core';
import Icon from '../../components/Icon';
import '../Home/index.css';
import { fetchEntries, showDeleteEntryDialog, showEntryDialog } from '../../actions/entries';

const EntriesTable = (props) => {
    const [time, setTime] = useState(null);
    const eventID = props.match && props.match.params && props.match.params.eventID;
    const gatedAccessID = props.match && props.match.params && props.match.params.gatedAccessID;

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        customToolbar: () => {
            return (
                <span>
                    <Button className="add_icon" href="" onClick={() => props.showDialog({})}>
                        <Icon className="add" icon="add"/>
                    </Button>
                </span>
            );
        },
        onSearchChange: (searchText) => {
            if (time) {
                clearInterval(time);
            }

            const interval = setTimeout(() => {
                props.fetchEntries(eventID, gatedAccessID, props.skip, props.limit, props.sortBy, props.order, searchText);
            }, 1000);

            setTime(interval);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchEntries(eventID, gatedAccessID, props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchEntries(eventID, gatedAccessID, props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchEntries(eventID, gatedAccessID, props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: true,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: '_id',
        label: 'Entry ID',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'ticket.id',
        label: 'Ticket ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'ticket.email_address',
        label: 'Email',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'ticket.owner_name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'status',
        label: 'Status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        <Button
                            className="delete_button"
                            onClick={() => props.showDeleteEntryDialog(value)}>
                            Delete
                            {props.addEntryInProgress ? <CircularProgress/> : null}
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item._id,
                item.ticket && item.ticket.id,
                item.ticket && item.ticket.email_address,
                item.ticket && item.ticket.owner_name,
                item.status,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

EntriesTable.propTypes = {
    addEntryInProgress: PropTypes.bool.isRequired,
    fetchEntries: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    showDeleteEntryDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    limit: PropTypes.number,
    match: PropTypes.shape({
        params: PropTypes.shape({
            eventID: PropTypes.string,
            gatedAccessID: PropTypes.string,
        }),
    }),
    order: PropTypes.string,
    searchKey: PropTypes.string,
    skip: PropTypes.number,
    sortBy: PropTypes.string,
    total: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.entries.entries.list,
        inProgress: state.entries.entries.inProgress,
        addEntryInProgress: state.entries.addEntry.inProgress,
        skip: state.entries.entries.skip,
        limit: state.entries.entries.limit,
        sortBy: state.entries.entries.sortBy,
        order: state.entries.entries.order,
        total: state.entries.entries.count,
        searchKey: state.entries.entries.searchKey,
    };
};

const actionsToProps = {
    fetchEntries,
    showDeleteEntryDialog,
    showDialog: showEntryDialog,
};

export default withRouter(connect(stateToProps, actionsToProps)(EntriesTable));
